import React, { Component } from 'react'

export default class TheShiftAsf2021 extends Component {

    componentDidMount(){
        window.location.href = 'https://www.eventbrite.com/e/141078893769/?discount=ASF2021';
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
